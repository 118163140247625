.List {
    list-style-type: square;
    list-style-position: outside;
}

li.List::marker{
    color: rgba(217, 217, 217, 0.5);
}

li.List {
    opacity: 0;
    animation: fade-in 500ms ease-in 3s 1 forwards;
}