.CodeModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(80%);
    max-width: 560px;
    background-color: var(--matteBlack);
    height: calc(100% - 100px);
    /* margin-left: -260px; */
    z-index: 9999;
    display: flex;
    flex-direction: column;
    border: 2px solid #FFFFFF;
    box-shadow: 0 10px 10px rgba(0,0,0,0.25);
  }

  .CodeModal-title {
    text-align: center;
    font-size: 16px;
  }

  .CodeModal-content {
    color: #FFFFFF;
    padding: 15px;
    text-align: justify;
    height: 100%;
    overflow-y: auto;
  }

  .CodeModal-close {
    text-align: center;
    font-family: 'BebasNeue';
    position: absolute;
    font-size: 24px;
    width: 100%;
    left: 0;
  }

  .CodeModal-footer {
    padding: 15px;
    bottom: 15px;
    border-radius: 0 0 3px 3px;
  }
  .CodeModal-header {
    padding: 15px;
    font-weight: 700;
    text-align: center;
    top: 15px;
    font-size: 16px;
    border: 0;
    border-bottom: 5px solid white;
    border-style: double;
    border-radius: 3px 3px 0 0;
  }
  .Body-remove__overlow {
    overflow: hidden !important;
  }
  .CodeModal-backdrop {
    position: fixed;
    display: block;
    width: 100vw;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
    height: 100vh;
  }

  .CodeModal-close > div {
    border: 1px solid #FFFFFF;
    width: fit-content;
    color: #FFFFFF;
    margin: auto;
    background-color: var(--matteBlack);
    padding: 4px 29px;
    cursor: pointer;
  }

  .CodeModal-close > div:hover {
    background-color: #44444b !important;
  }