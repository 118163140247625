.ConnectedLine {
    display: flex;
    justify-content: center;
}

.ConnectedLine-line {
    height: 1px;
    background-color: #D9D9D9;
    flex-grow: 1;
}

.ConnectedLine {
    opacity: 0;
    animation: fade-in 500ms ease-in 3s 1 forwards;
}