html, body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    height: 0;
    background-color: var(--matteBlack);
    color: #FFFFFF;
    transition-duration: 1s;
    font-size: 13px;
    font-family: "RobotoThin";
}
body {
    overflow: hidden;
}

@media screen and (max-width:968px){
    body{
        overflow: auto !important;
    }
}


a {
    text-decoration: none;
    text-transform: none;
    color: #b4b2b2;
    cursor: pointer;
}

.App {
    display: flex;
    justify-content: space-around;
    height: 100vh;
    width: 100vw;
    flex-direction: row;
    flex: 1 1 auto;
}

:root {
    --matteBlack: #28282B;
    --mattePurple: #594559;
    --matteRed: #9D6072;
    --matteOrange: #DC8273;
    --matteLightOrange: #FFB668;
    --matteYellow: #F9F871;
    --line1_height: 0%
  }

@font-face {
    font-family: 'RobotoRegular';
    src: url('./fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'RobotoMedium';
    src: url('./fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
    font-family: 'RobotoThin';
    src: url('./fonts/Roboto/Roboto-Thin.ttf');
}

@font-face {
    font-family: 'RobotoLight';
    src: url('./fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
    font-family: 'RobotoBold';
    src: url('./fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'BebasNeue';
    src: url('./fonts/Bebas_Neue/BebasNeue-Regular.ttf');
}