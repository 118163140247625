.MenuItem {
    width: 150px;
    height: 150px;
    border: 1px solid white;
    border-radius: 3px;
    display: flex;
    opacity: 0;
    flex-direction: column;
}

.MenuItem:hover {
    box-shadow: 0px 0px 10px 0px rgb(255, 255, 255) !important;
    text-shadow: 2px 2px 4px #ffffff;
    filter: url(#svg-glow);
}

.menu-item-animation {
    animation: menu-item 2s 2s 1 ease-in-out forwards;
}

@keyframes menu-item {
    0% {opacity: 0;}
    50% {box-shadow: 0px 0px 10px 0px rgb(255, 255, 255);}
    100% {opacity: 100; box-shadow: unset}
}

.MenuItem-icon {
    display: flex;
    justify-content: center;
    height: 80%;
    align-items: center;
}

.MenuItem svg {
    display: block;
    margin: auto;
}

.MenuItem-svg {
    width: 60%;
    height: 60%;
}

.MenuItem-title {
    text-align: center;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid white;
}

@media only screen and (max-width: 320px){
    .MenuItem-svg {
        display: none;
    }
    .MenuItem-icon {
        display: none;
    }
    .MenuItem {
        width: 100%;
        height: 100%;
        padding: 1rem;
        display: flex;
        opacity: 0;
        flex-direction: column;
    }
    .MenuItem-title {
        text-align: center;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 0px solid white;
    }
  }