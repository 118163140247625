.text-xs {
    font-size: 10px;
    font-family: "RobotoThin";
    line-height: 16px;
}

.text-sm {
    font-size: 13px;
    font-family: "RobotoThin";
    line-height: 22px;
}

.text-m {
    font-size: 20px;
    font-family: "RobotoThin";
    line-height: 22px;
}

.px-3 {
    padding: 0 1rem 0 1rem;
}

.strike-through-text {
    display: flex;
    flex-direction: row;
  }
  .strike-through-text:before, h1:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  .strike-through-text:before {
    margin-right: 10px
  }
  .strike-through-text:after {
    margin-left: 10px
  }

.py-1 {
    padding: 0.25rem 0 0.25rem 0;
}

.py-2 {
    padding-top: 0.50rem;
    padding-bottom: 0.50rem;
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.align-center {
    align-self: center;
}

.align-bottom {
    align-self: end;
}

@media screen and (max-width:968px){
    .align-bottom-mobile{
      align-self: unset !important;
    }
}

.align-center {
    align-self: center;
}

@media screen and (max-width:968px){
    .align-center-mobile{
      align-self: unset !important;
    }
}

.mt-5 {
    margin-top: 3rem;
}

.spacer-sm {
    margin: 5px;
}

.spacer-md {
    margin: 10px;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.t-r {
    text-align: right;
}

.w-a {
    width: auto;
}

.pl-1 {
    padding-left: 0.25rem;
}

.pr-1 {
    padding-right: 0.25rem;
}

.p-3 {
    padding: 1rem;
}

.m-3 {
    margin: 1rem;
}

.px {
    padding: 0 0.25rem 0 0.25rem;
}

.mx-1 {
    margin: 0 0.25rem 0 0.25rem;
}

.mx-3 {
    margin: 0 1rem 0 1rem;
}

@media screen and (max-width:968px){
    .md-stack{
      flex-direction: column !important;
    }
}

@media screen and (max-width:968px){
    .h-mobile{
      height: unset !important;
    }
}

@media screen and (max-width:968px){
    .hidden{
      display: none !important;
    }
}

@media screen and (max-width:968px){
    .w-mobile{
      width: unset !important;
    }
}

.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 1 !important;
}

.mxw-5 {
    max-width: 500px !important;
}

.mnw-4 {
    min-width: 400px !important;
}

.mnw-3 {
    min-width: 300px !important;
}

.d-flex {
    display: flex;
}