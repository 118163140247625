.PlayerCard {
    width: 50%;
    overflow: auto;
}

.PlayerCard::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  .PlayerCard::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  /* Handle */
  .PlayerCard::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: 2px;
  }
  

@media screen and (max-width:968px){
    .PlayerCard{
        width: 100% !important;
        height: 100%;
    }
    .PlayerCard-container {
        max-width: unset !important;
    }
}


.PlayerCard-container {
    padding: 25px;
}

@media screen and (max-width: 320px) {
    .PlayerCard-container {
        min-width: 200px !important;
        padding: 10px !important;
    }
}

.PlayerCard-name {
    font-family: "BebasNeue";
    font-size: 32px;
}

.PlayerCard-underline {
    background-color: #FFFFFF;
    height: 8px;
    width: 0%;
    margin-bottom: 8px;
}