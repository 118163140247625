.LineTitle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LineTitle-line {
    height: 2px;
    /* width: 35%; */
    background-color: #FFFFFF;
}

.LineTitle-title {
    width: 30%;
    text-align: center;
}