.fill-out-width-animation {
    animation: fill-out-width 500ms linear 1s 1 forwards;
}
@keyframes fill-out-width {
    0% { width: 0%}
    30% {width: 30%;}
    100% {width: 100%}
}

.fade-in-animation {
    animation: fade-in 500ms ease-in 2s 1 forwards;
}

@keyframes fade-in {
    0% {opacity: 0;}
    100% {opacity: 1;}
}