.Menu {
    width: 50%;
    position: relative;
    transition: all 2s;
    /* padding: 1rem; */
    padding: 0 1rem 1rem 1rem;
}

::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: 2px;
  }

@media only screen and (min-width: 1068px) {
    .Menu {
        height: 450px;
        width: 800px;
    }
  }

  @media only screen and (min-width: 768px) and (max-height: 500px){
    .Menu {
        width: 800px;
        height: calc(400px - 36px);
    }
  }

  @media (max-height: 360px) {
    .Menu {
      /* height: 100% !important; */
      height: 300px;
    }
  }

  @media (max-height: 300px) {
    .Menu {
      /* height: 100% !important; */
      height: 200px;
    }
  }

  @media (min-width: 1024px) and (max-height: 600px){
    .Menu {
        overflow: auto;
    }
    .InsideMenu {
      overflow-y: unset !important;
    }
    .PlayerCard-container {
        padding: 5px;
    }
    .PlayerCard {
        height: 100%;
    }
  }

  @media (min-width: 2000px) and (max-height: 600px) {
    .Menu {
        /* width: 600px; */
        height: 450px;
    }
    .App {
        justify-content: space-between;
    }
  }


.Menu .menu--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Menu::before {
    content: '';
    animation: line1 2s 2s ease-in-out forwards;
    /* height: 1px;  */
    width: 1px;
    transition: all 2s;
    position: absolute;
    right: 100%;
    bottom: 0; 
    background-color: #FFFFFF; 
  }

.Menu::after {
    content: '';
    animation: line2 2s 2s ease-in-out forwards;
    transition: all 2s;
    /* height: 20px;  */
    width: 1px;
    position: absolute;
    right: 100%;
    top: 0; 
    background-color: #FFFFFF; 
  }

@keyframes line1 {
    0% {height: 0;}
    100% {height: 40%}
}

@keyframes line2 {
    0% {height: 0;}
    100% {height: 40%;}
}

/* @media screen and (min-width:1111px){
    .Menu::before {
        display: none;
      }
    
    .Menu::after {
        display: none;
      }

      .Menu .menu--flex {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        justify-content: center;
    }
} */
  
@media screen and (max-width:968px){
    .Menu{
        width: 100% !important;
        padding: 0 !important;
        margin-top: -5px !important;
        /* flex-direction: column; */
    }
    .InsideMenu .BackButton {
      position: unset !important;
    }
    .InsideMenu {
      overflow-y: unset !important;
    }
    .About {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
}