.InsideMenu {
    /* height: 300px; */
    border-radius: 3px;
    opacity: 1;
    overflow-y: auto;
    padding: 0 1rem 1rem 1rem;
}

@media only screen and (min-width: 968px) {
    .InsideMenu {
        height: calc(450px + 1rem);
    }
}

.InsideMenu .BackButton {
    background-color: var(--matteBlack);
    width: 100%;
    border: 1px solid white;
    padding-left: 1rem;
    position: fixed;
    font-size: 20px;
    color: #FFFFFF;
    text-align: left;
    font-family: "BebasNeue";
    font-size: 28px;
    text-shadow: 0;
    animation: glow-reverse 100ms ease-in forwards;
}

.InsideMenu .PropsTab:hover {
    cursor: pointer;
    animation: glow 100ms ease-in forwards;
}

@keyframes glow {
    0% {text-shadow: 0;}
    100% {text-shadow: 2px 2px 4px #ffffff;}
}

@keyframes glow-reverse {
    0% {text-shadow: 2px 2px 4px #ffffff;}
    100% {text-shadow: 0;}
}
