.LearningSphere {
    display: grid;
    grid-template-columns: 20% 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 5px;
    min-height: 100svh;
    height: 100svh;
    min-width: 100svw;
    row-gap: 5px;
    position: fixed;
    touch-action: none;
    -webkit-overflow-scrolling: touch;
    grid-template-areas: 
    "header header header"
    "navigation body body"
}

@media only screen and (max-width: 768px) {
    .LearningSphere {
        grid-template-areas: 
        "header header header"
        "body body body"
    }

    .LearningSphere-navigation {
        display: none;
    }

    .LearningSphere-header-button {
        font-size: 18px !important;
        padding: 5px !important;
    }
}

.LearningSphere-header {
    display: flex;
    border: 1px solid white;
    justify-content: space-between;
    align-items: center;
    grid-area: header;
}

.LearningSphere-body {
    border: 1px solid white;
    justify-content: space-between;
    overflow: auto;
    padding: 5px;
    overflow-y: auto;
    padding-bottom: env(safe-area-inset-bottom) !important;
    align-items: center;
    grid-area: body;
}
.LearningSphere-navigation {
    border: 1px solid white;
    justify-content: space-between;
    align-items: center;

    grid-area: navigation;
}

.LearningSphere-header-button {
    font-family: "BebasNeue";
    background-color: transparent;
    border: 0px solid transparent;
    color: #FFFFFF;
    text-align: left;
    min-height: 34px;
    height: 34px;
    font-size: 24px;
    text-shadow: 0;
    animation: glow-reverse 100ms ease-in forwards;
}

.LearningSphere-header-date {
    padding: 1px 6px;
    font-family: "BebasNeue";
    font-size: 18px;
}

.LearningSphere-header-percentage {
    font-family: "BebasNeue";
    font-size: 18px;
}

.LearningSphere-header-button:hover {
    cursor: pointer;
    animation: glow 100ms ease-in forwards;
}

@keyframes glow {
    0% {text-shadow: 0;}
    100% {text-shadow: 2px 2px 4px #ffffff;}
}

@keyframes glow-reverse {
    0% {text-shadow: 2px 2px 4px #ffffff;}
    100% {text-shadow: 0;}
}

.LearningSphere-navigation-list {
    list-style: none;
    padding: 0;
}

.LearningSphere-navigation-list li {
    font-size: 16px;
    margin: 5px;
    padding: 5px;
    border: 1px solid gray;
}

.LearningSphere-navigation-list li:hover {
    cursor: pointer;
    background-color: #ffffff7b;
}

.LearningSphere-body::-webkit-scrollbar {
    width: 8px;
  }


  .LearningSphere-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 0px;
    margin-top: 0px;
    border: 1px solid white;
    margin-bottom: 0px;
}

  /* Handle */
  .LearningSphere-body::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
  }

 summary {
    padding: 10px;
    cursor: pointer;
    transition: .5s;
    user-select: none;
    border-bottom: 2px solid;
  }

  details[open] {
    animation-name: slideDown;
    animation-duration: 0.3s;
    transition-duration: 0.3s;
      padding: 20px;
    animation-fill-mode: forwards;
  }

  details {
    animation-name: slideUp;
    animation-duration: 0.3s;
    padding: 0;
    transition-duration: 0.3s;
    animation-fill-mode: forwards;
  }

details > summary:hover {
    color: darkgrey;
  }
