.About .title {
    font-family: "BebasNeue";
    font-size: 24px;
}

.About {
    /* margin-top: calc(36.5px + 1rem); */
    padding-top: 36.5px;
}

.About .icon {
    border-radius: 3px;
    margin-right: 1rem;
    margin-top: 1rem;
    border: 0;
    display: flex;
    background-color:rgba(255, 255, 255, 1);;
    align-items: center;
    transition: all 700ms;
    font-family: 'RobotoRegular';
    cursor: pointer;
}

.social-icons-flex a {
    text-decoration: none;
    text-transform: none;
    color: black;
}

.About .icon:hover {
    background-color:rgba(255, 255, 255, 0.6);
}

.About .social-title {
    font-size: 12px;
    font-family: "RobotoRegular";
    color: black;
}

.About .social-icons-flex {
    display: flex;
    justify-content: start;
}

@media screen and (max-width:968px){
    .About .social-icons-flex {
        justify-content: center;
    }
}

.paragraph {
    text-align: justify;
    margin-top: 10px;
}