.Knowledge_box {
    background-color: rgb(71, 71, 71);
    font-family: "RobotoRegular";
    color: #ffffff;
    cursor: pointer;
    border-radius: 3px;
    padding: 5px;
    margin: 10px;
    transition-duration: 500ms;
    border-left: 5px solid white;
    background: linear-gradient(to left, rgb(71, 71, 71) 50%, rgb(255, 255, 255) 50%) right;
    background-size: 200%;
    transition: .7s ease-out;
    transition-delay: .1s;
}

.Knowledge_title {
    font-family: "RobotoRegular";
}

.Knowledge_description {
    font-family: "RobotoThin";
}

.Knowledge_box a {
    font-size: 13px;
    color: #ffffff;
    transition-duration: 500ms;
        transition: .7s ease-out;
        transition-delay: .1s;
}

.Knowledge_box:hover {
    margin: 5px;
    color: #000000;
    background-position: left;
}

.Knowledge_box:hover a {
    color: #000000;
}

@keyframes fill-color {
    0% {background-color: rgb(71, 71, 71);}
    100% {background-color: #ffffff !important;}
}

.Knowledge {
    margin: auto;
    padding-top: 36px;
}

@media screen and (max-width:968px){
    .Knowledge{
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
}