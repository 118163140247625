.PercentageBar-titleAndPercentage{
    display: flex;
    justify-content: space-between;
}

.PercentageBar-bar {
    height: 5px;
    background-color: #D9D9D9;
    opacity: 0.5;
}
